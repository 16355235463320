import Bancosantandermx from './Bancosantandermx.vue';

export default {
  component: Bancosantandermx,
  settingsSchema: {
    fields: [
      {
        type: 'text',
        label: 'URL',
        model: 'url',
      },
      {
        type: 'text',
        label: 'Company Id',
        model: 'idCompany',
      },
      {
        type: 'text',
        label: 'Branch Id',
        model: 'idBranch',
      },
      {
        type: 'text',
        label: 'User',
        model: 'user',
      },
      {
        type: 'text',
        label: 'Pwd',
        model: 'pwd',
      },
      {
        type: 'text',
        label: 'Key',
        model: 'key',
      },
      {
        type: 'text',
        label: 'data0',
        model: 'data0',
      },
    ]
  }
}